const CONSUMER_ORGANIZATION_ID = 'UpM28mnfW65vJkbqEATtKvxm';

const stagingEnvs = ['stag', 'uat'] as const;
const prodEnvs = ['ihis', 'prod', 'prod-a', 'prod-c', 'production', 'tt'] as const;
const canaryEnvs = ['prod-a', 'prod-c'] as const;
const ihisEnvs = ['ihis'] as const;
const prodDisasterRecoveryEnvs = ['prod-dr'] as const;
const otherEnvs = ['local', 'productionOauth'] as const;

type ENV =
  | typeof stagingEnvs[number]
  | typeof prodEnvs[number]
  | typeof canaryEnvs[number]
  | typeof ihisEnvs[number]
  | typeof prodDisasterRecoveryEnvs[number]
  | typeof otherEnvs[number];

type EnvRecord = Partial<Record<ENV, string>>;

const CONFIG_DEFAULT = {
  adminFeatureBranch: null,
  allowAlertLifespan: false,
  allowAvailability: false,
  allowConversationExpiration: false,
  allowGA: false,
  allowGAVC: false,
  allowMentions: false,
  allowTypedMentions: false,
  allowNewConversationUI: false,
  allowOTP: false,
  allowPatientContext: false,
  allowPBXRoles: false,
  allowReactions: false,
  allowRolesPerformance: false,
  allowRTU: false,
  allowSearchParity: false,
  allowUT: false,
  allowVoipCheck: false,
  apiEnv: 'production' as ENV,
  assetDomain: 'assets.tigerconnect.com',
  autoAckSuccessfullyHandledEvents: true,
  autoSignOutOnBrowserUnload: true,
  baseUrl: null, // defaults to what is in apiEnv but can be overridden
  botUsers: [],
  clearTypingStatusThrottleDelay: 2000,
  clearTypingStatusTimeout: 8000,
  cloudfront: {},
  cloudfrontDomain: 'cloudfront.net',
  condensedReplays: false,
  consumerOrganizationId: CONSUMER_ORGANIZATION_ID,
  detectBangReplayTimeout: 10000,
  enableMessageStatusSync: false,
  ensureFullConversationCounterParty: false,
  ensureFullConversationGroupMembers: false,
  eventCollectionInterval: 50,
  eventDrainTimeout: 4000,
  events: {
    autoAck: false,
    autoDeliver: false,
    canConfirm: true,
    closeAfterMessages: false,
    emojiReactions: true,
    heartbeat: true,
    hidden: false,
    includeDistributionListEvents: true,
    isTyping: true,
    keepAlive: false,
    msgDeliveryDelay: 0,
    multiOrg: true,
    noOfflineMessages: false,
    renderMetadata: true,
    vwr: true,
  },
  eventsAutoReconnect: true,
  eventsAutoReconnectDelay: 2000,
  eventsAutoReconnectMaxDelay: 16000,
  eventsClearRetryHistoryInterval: 60000,
  eventsDebugging: false,
  eventsHeartbeatIntervalInvisible: 30000,
  eventsHeartbeatIntervalVisible: 12000,
  eventsOpenTimeout: 12000,
  expireBangsCleanUpTTL: 24 * 5,
  extendAutoForwardOptions: false,
  flushAckInterval: 1000,
  flushAckMaxConnections: 2,
  flushAckMaxEventsSSE: 50,
  flushAckMaxEventsWS: 100,
  flushFindRecipientStatusInterval: 150,
  flushFindRecipientStatusMaxCount: 20,
  flushSetMessageStatusInterval: 1000,
  flushSetMessageStatusMaxConnections: 2,
  flushSetMessageStatusMaxCount: 500,
  isFHIRLaunchSession: false,
  keepConversationsForAllForums: false,
  loadEntityMaxConnections: 2,
  localAdminUrl: null,
  localCnUrl: null,
  localMessengerUrl: null,
  localRolesUrl: null,
  logLevel: 'error',
  manuallyRequestEventQueueBatch: false,
  maxMetadataKeyCount: 11,
  maxRecipientStatusMultiBatch: 200,
  messageStatusSyncSampleRate: 0,
  noSearchResultsOnEmptyQuery: false,
  organizationRostersChangeThrottleDelay: 500,
  placeholderEntityAllowLoading: true,
  placeholderEntityDelayBeforeRefreshing: 100,
  processingEvents: false,
  productKey: 'bc1oOf3DZXbnaNZ2LCWZGvSg',
  pushAgent: 'consumer',
  readMouseActivityLogout: false,
  reportErrors: false,
  reportPerformance: false,
  representativeOrgId: null,
  rolesFeatureBranch: null,
  serviceLogLevels: {},
  shouldSendReports: false,
  showCareTeamAssignments: false,
  signUpHashKey: '21d2ff3ffbf',
  signUpHashSalt: '%(xx*&x$&*%&',
  trackConversationHanging: false,
  ttRoute: null,
  useWebSockets: false,
  useWebSocketsDisableSSEFallback: false,
  version: '1.0',
  wantCondensedReplays: false,
  wantWebSockets: false, //param to be used by client
};

export type ClientConfig<CONFIG = typeof CONFIG_DEFAULT> = {
  [key in keyof CONFIG]: CONFIG[key] extends null
    ? unknown
    : CONFIG[key] extends Record<string, never>
    ? Record<string, unknown>
    : CONFIG[key];
};

const CLOUDFRONT = {
  development: {
    d1n6bomzxlt9xn: 'tigertext/production/avatar',
    d18oyzjkq5093g: 'tigertext/production/avatar-group',
  },
  production: {
    d1n6bomzxlt9xn: 'tigertext/production/avatar',
    d18oyzjkq5093g: 'tigertext/production/avatar-group',
  },
  tt: {
    d1n6bomzxlt9xn: 'tigertext/production/avatar',
    d18oyzjkq5093g: 'tigertext/production/avatar-group',
  },
  ihis: {
    avatar: '',
    groupAvatar: '',
  },
} as const;

export type Configuration = {
  CONSUMER_ORGANIZATION_ID: typeof CONSUMER_ORGANIZATION_ID;
  BASE_URLS: EnvRecord;
  ADMIN_URLS: EnvRecord;
  CN_URLS: EnvRecord;
  FORGOT_PASSWORD_URLS: EnvRecord;
  INTEGRATION_URLS: EnvRecord;
  TC_URLS: EnvRecord;
  ROLES_URLS: EnvRecord;
  WEB_INFRA_URLS: EnvRecord;
  WEBSOCKET_URLS: EnvRecord;
  PROD_ENVS: typeof prodEnvs;
  CLOUDFRONT: typeof CLOUDFRONT;
  CONFIG_DEFAULT: ClientConfig;
};

const configuration: Configuration = {
  CONSUMER_ORGANIZATION_ID,

  BASE_URLS: {
    local: 'http://localhost:8888',
    productionOauth: 'http://52.41.133.15:8000/tigertextapi',
  },

  ADMIN_URLS: {},
  CN_URLS: {},
  FORGOT_PASSWORD_URLS: {},
  INTEGRATION_URLS: {},
  TC_URLS: {},
  ROLES_URLS: {},
  WEB_INFRA_URLS: {},
  WEBSOCKET_URLS: {},

  PROD_ENVS: prodEnvs,

  CLOUDFRONT,
  CONFIG_DEFAULT,
};

stagingEnvs.forEach((env) => {
  configuration.BASE_URLS[env] = `https://${env}-devapi.tigertext.me`;
  configuration.FORGOT_PASSWORD_URLS[env] = `https://login.tigerconnect.com/${env}/app/forgot`;
  configuration.CN_URLS[env] = `https://${env}-console.tigertext.me`;
  configuration.TC_URLS[env] = `https://api.${env}.tigerconnect.com`;
  configuration.ADMIN_URLS[env] = `/${env}/app/admin`;
  configuration.ROLES_URLS[env] = `/${env}/app/roles/index.html`;
  configuration.INTEGRATION_URLS[env] = 'https://integration.uat.tigerconnect.com';
  configuration.WEB_INFRA_URLS[env] = 'https://web.uat.tigerconnect.com';
  configuration.WEBSOCKET_URLS[env] = `https://${env}-devapi.tigertext.me`;
});

prodEnvs.forEach((env) => {
  configuration.BASE_URLS[env] = 'https://api.tigertext.me';
  configuration.FORGOT_PASSWORD_URLS[env] = 'https://login.tigerconnect.com/app/forgot';
  configuration.CN_URLS[env] = 'https://home.tigertext.com';
  configuration.TC_URLS[env] = `https://api.tigerconnect.com`;
  configuration.ADMIN_URLS[env] = '/app/admin';
  configuration.ROLES_URLS[env] = '/app/roles/index.html';
  configuration.INTEGRATION_URLS[env] = 'https://integration.tigerconnect.com';
  configuration.WEB_INFRA_URLS[env] = 'https://web.tigerconnect.com';
  configuration.WEBSOCKET_URLS[env] = 'https://api.tigertext.me';
});

canaryEnvs.forEach((env) => {
  const fragment = env.replace('prod-', '');
  configuration.BASE_URLS[env] = `https://canary-${fragment}-api.tigertext.me`;
});

ihisEnvs.forEach((env) => {
  configuration.CN_URLS[env] = `https://${env}.tigertext.com`;
});

prodDisasterRecoveryEnvs.forEach((env) => {
  configuration.BASE_URLS[env] = `https://${env}-api.tigertext.me`;
  configuration.CN_URLS[env] = `https://${env}.tigertext.com`;
  configuration.TC_URLS[env] = `https://api.${env}.tigerconnect.com`;
  configuration.ADMIN_URLS[env] = `/${env}/app/admin`;
  configuration.ROLES_URLS[env] = `/${env}/app/roles/index.html`;
});

export default configuration;
