import React from 'react';
import { actions, ReduxState, useAppDispatch, useAppSelector } from '../../../redux-stores';
import { getIfUserIsNotInConversation } from '../../utils/getIfUserIsNotInConversation';
import EntitiesSelector from '../Collaboration/Entities/EntitiesSelector';
import { Conversation, Role, User } from '../../../types';
import style from './MentionMemberFloatingModal.module.css';

const { setModal } = actions;

type MentionMemberSelectorProps = {
  currentUserId: string;
  currentConversation: Conversation;
  selectEntity: (entity: Role | User) => void;
  setMentionMemberFloatingModal: (isOpen: boolean) => void;
};

export default function MentionMemberFloatingModal({
  currentConversation,
  currentUserId,
  selectEntity,
  setMentionMemberFloatingModal,
}: MentionMemberSelectorProps) {
  const currentOrgId = useAppSelector((state: ReduxState) => state.session.currentOrganizationId);
  const dispatch = useAppDispatch();

  const isCurrentConversationGroup =
    currentConversation?.counterPartyType?.toLowerCase() === 'group';

  const closeModal = () => {
    setMentionMemberFloatingModal(false);
  };

  const selectUser = (entity: Role | User) => {
    setMentionMemberFloatingModal(false);
    selectEntity(entity);

    const isUserNotAConversationMember = getIfUserIsNotInConversation(
      currentConversation,
      currentUserId,
      entity?.botUser?.id || entity.id
    );

    const membersToAdd = [
      { displayName: entity.displayName, id: entity?.botUser?.id || entity.id },
    ];

    if (
      isUserNotAConversationMember &&
      isCurrentConversationGroup &&
      currentConversation?.counterParty.groupType !== 'ROLE_P2P'
    ) {
      dispatch(
        setModal({
          name: 'addMemberToConversation',
          data: {
            // Needed for serialize elements
            membersToAdd: JSON.parse(JSON.stringify(membersToAdd)),
          },
        })
      );
    } else {
      closeModal();
    }
  };

  const clearEntity = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className={style.MentionMemberFloatingModal}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
          closeModal();
        }
      }}
      id={'MentionMemberFloatingModal'}
    >
      <EntitiesSelector
        allowRolesRecipients={true}
        clearEntity={clearEntity}
        currentUserId={currentUserId}
        isEntityInConversationCheck={true}
        isMentionMemberFloatingModalOpen={true}
        organizationId={currentOrgId}
        selectEntity={selectUser}
        disableDndEntities={false}
        shouldDisplayOnDutyText={false}
        toggleHandler={() => setMentionMemberFloatingModal(false)}
      />
    </div>
  );
}
