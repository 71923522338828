import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import TCClient from '../../../../../client';
import styles from './Persona.module.css';
import { CtaButton } from 'admin/shared-components/CtaButton/CtaButton';
import { Table } from 'admin/shared-components/Table/Table';
import { ActionBar } from 'admin/shared-components/ActionBar/ActionBar';

type PersonaTableProps = {
  isCreateMode: boolean;
  isSuccessToastOpen: boolean;
  setIsCreateMode: (isCreateMode: boolean) => void;
  setIsSuccessToastOpen: (isSuccessToastOpen: boolean) => void;
};

type PersonaRow = {
  id: string;
  titles: string[];
  organizationId: string;
  name: string;
  description: string;
  parentPersonaId?: string;
};

export default function PersonaTable({ setIsCreateMode }: PersonaTableProps) {
  const [rows, setRows] = useState<PersonaRow[]>([]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const updateSearchInputValue = (text: string) => {
    setSearchInputValue(text);
  };

  const columns = [
    { field: 'name', headerName: 'Persona Name' },
    { field: 'titles', headerName: 'Title(s)' },
    { field: 'description', headerName: 'Description' },
    { field: 'parentPersonaId', headerName: 'Parent' },
  ];

  const getTableData = useCallback(async ({ rows }: { rows: PersonaRow[] }) => {
    const response = (await TCClient.persona.findPersonas()) as unknown as PersonaRow[];
    const formattedRows = response.map(
      (persona: PersonaRow) =>
        ({
          ...persona,
          titles: persona.titles.join(', '),
        } as unknown as PersonaRow)
    );
    setRows(formattedRows);
  }, []);

  const onRowEdit = (row: PersonaRow) => {
    // edit Row actions
  };

  useEffect(() => {
    getTableData({ rows: [] });
  }, [getTableData]);

  const actions = [{ name: 'Edit', type: 'edit' }];

  return (
    <div className={classNames(styles.personaTable)}>
      <ActionBar
        hasSearch
        onInputChange={(e) => updateSearchInputValue(e.target.value)}
        searchValue={searchInputValue}
        dataTestId={'personaSearch'}
        handleClearClick={() => {
          getTableData({ rows: [] });
          setSearchInputValue('');
        }}
      />
      <div className={classNames(styles.createButton)}>
        <CtaButton primary label={'Create New Persona'} onClick={() => setIsCreateMode(true)} />
      </div>
      <Table
        actionsOrder={actions}
        columns={columns}
        onRowClick={onRowEdit}
        onRowEdit={onRowEdit}
        rows={rows}
      />
    </div>
  );
}
