// @ts-nocheck
import BaseAPI from './BaseAPI';

export default class PersonaAPI extends BaseAPI {
  async findPersonas() {
    // const endpoint = `${this.host.tcUrl}/v1/user-service/Personas`;
    // const res = await this.httpClient.get(endpoint);
    return [
      {
        id: '6e129dc6-cfc9-4136-befb-c108b3b48a21',
        titles: ['nurse'],
        settings: [['tt_settings']],
        permissions: [['tt_admin']],
        organizationId: '12329dc6-cfc9-4136-befb-c108b3b48123',
        name: 'Persona1',
        description: 'This is a test Persona',
        parentPersonaId: 'asd29dc6-cfc9-4136-befb-c108b3b48asd',
      },
      {
        id: '6e129dc6-cfc9-4136-befb-c108b3b48a232',
        titles: ['title2'],
        settings: [['tt_settings']],
        permissions: [['tt_admin']],
        organizationId: '12329dc6-cfc9-4136-befb-c108b3b48123',
        name: 'Persona2',
        description: 'This is a test 2 Persona',
        parentPersonaId: 'asd29dc6-cfc9-4136-befb-c108b3b48ass',
      },
    ];
  }
}
