import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import BroadcastListOrigins from '../../../../models/enums/BroadcastListOrigins';
import BEM from '../../../bem';
import { useInfiniteSearchResults } from '../../../hooks';
import { mobxInjectSelect } from '../../../utils';

import RefreshButton from '../../RefreshButton';
import { DotsIndicator, CollapsingSearchBar } from '../../';

import { ReactComponent as DeleteButtonSvg } from '../../../images/delete_button.svg';
import { ProcessingIndicator } from '../PatientsList';
import { PatientBroadcastFormHeader, PatientBroadcastListDetails, PatientSearchBox } from './';

const DEBOUNCE_TIMEOUT = 500;

const classes = BEM.with('PatientBroadcastForm');
const DEFAULT_SORT_BY = ['patient_display_name', 'is_patient_contact', 'display_name'];
const DEFAULT_SORT_ORDER = ['asc', 'asc', 'asc'];

const PatientBroadcastForm = ({
  addBroadcastListMembers,
  destroy,
  findAllBroadcastListMemberIds,
  id: _id,
  isAdmin,
  isCSVUploading,
  loadBroadcastList,
  loadBroadcastListMembers,
  openModal,
  searchBroadcastLists,
}) => {
  const {
    isLoading: isLoadingMore,
    totalHits,
    results,
    resetSearch,
    scrollContainerRef,
    updateOptions,
  } = useInfiniteSearchResults(loadBroadcastListMembers, {
    sortBy: DEFAULT_SORT_BY,
    sortOrder: DEFAULT_SORT_ORDER,
  });

  const [addingPendingMembers, setAddingPendingMembers] = useState(false);
  const [broadcastListDetails, setBroadcastListDetails] = useState({
    displayName: '',
  });
  const [currentMemberIds, setCurrentMemberIds] = useState([]);
  const [currentMembersSearchQuery, setCurrentMembersSearchQuery] = useState('');
  const [fetchingBroadcastList, setFetchingBroadcastList] = useState(!!_id);
  const [groupedMembers, setGroupedMembers] = useState([]);
  const [groupedPendingMembers, setGroupedPendingMembers] = useState([]);
  const [id, setId] = useState(_id);
  const [isSaving, setIsSaving] = useState(false);
  const [listError, setListError] = useState('');
  const [pendingMemberIds, setPendingMemberIds] = useState([]);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [shouldFetchBroadcastList, setShouldFetchBroadcastList] = useState(false);
  const [sortBy, setSortBy] = useState('patient_display_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const groupedMembersMap = useRef(new Map());
  const groupedPendingMembersMap = useRef(new Map());
  const setCurrentMembersSearchQueryDebounced = useRef(
    debounce(setCurrentMembersSearchQuery, DEBOUNCE_TIMEOUT)
  );

  const isLoading = fetchingBroadcastList || isLoadingMore;

  const fetchBroadcastList = useCallback(async () => {
    setFetchingBroadcastList(true);

    const broadcastList = await loadBroadcastList(id, { bypassCache: true });
    const { createdOn, displayName, memberCount, adminOnly, createdBy } = broadcastList;

    setBroadcastListDetails({
      adminOnly,
      createdBy,
      createdOn,
      displayName,
      memberCount,
    });
    setFetchingBroadcastList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCurrentMembersSearchQueryChange = (value) => {
    if (value !== currentMembersSearchQuery) {
      setCurrentMembersSearchQueryDebounced.current(value);
    }
  };

  const resetPendingMembers = () => {
    setGroupedPendingMembers([]);
    groupedPendingMembersMap.current.clear();
    setPendingMemberIds([]);
  };

  const refreshListMembers = (options) => {
    groupedMembersMap.current.clear();
    setGroupedMembers([]);
    resetSearch();
    updateOptions(options);
    if (options?.reloadList) setShouldFetchBroadcastList(true);
  };

  const resetCurrentMembers = () => {
    if (id) {
      findAllBroadcastListMemberIds(id).then((memberIds) => {
        setCurrentMemberIds(memberIds);
      });
    }

    setCurrentMembersSearchQuery('');
    setSelectedMemberIds([]);
    setShouldFetchBroadcastList(true);
    refreshListMembers();
  };

  const handleBulkAdd = async () => {
    setAddingPendingMembers(true);

    try {
      await addBroadcastListMembers({ id, members: pendingMemberIds });

      // ES data is not updated immediately
      setTimeout(() => {
        resetCurrentMembers();
        resetPendingMembers();
        setAddingPendingMembers(false);
      }, 1000);
    } catch (err) {
      console.error(err);
      setAddingPendingMembers(false);
      openModal('patientAdminFailure', {
        body: 'Unable to update broadcast list. Please try again.',
      });
    }
  };

  const handleBulkRemove = () => {
    openModal('removePatientBroadcastListMember', {
      id,
      members: selectedMemberIds,
      resetCurrentMembers,
    });
  };

  const resetForm = (newId) => {
    setId(newId);
    if (newId) {
      findAllBroadcastListMemberIds(newId).then((memberIds) => {
        setCurrentMemberIds(memberIds);
      });
    }

    destroy();
    setCurrentMembersSearchQuery('');
    setGroupedPendingMembers([]);
    groupedPendingMembersMap.current.clear();
    setShouldFetchBroadcastList(true);
    refreshListMembers({ id: newId });
  };

  const togglePendingMembers = (member) => {
    const patientId = member.patientId;

    if (groupedPendingMembersMap.current.has(patientId)) {
      const groupedMembers = groupedPendingMembersMap.current.get(patientId);
      let existed = false;

      if (groupedMembers.patient && groupedMembers.patient.id === member.id) {
        groupedMembers.patient = null;
        existed = true;
      }

      if (groupedMembers.contacts.find((contact) => contact.id === member.id)) {
        groupedPendingMembersMap.current.set(patientId, {
          patient: groupedMembers.patient,
          contacts: groupedMembers.contacts.filter((contact) => contact.id !== member.id),
        });
        existed = true;
      }

      if (!existed) {
        if (member.isPatient) {
          groupedMembers.patient = member;
        } else {
          groupedMembers.contacts.push(member);
        }
      }
    } else {
      groupedPendingMembersMap.current.set(patientId, {
        patient: null,
        contacts: [],
      });

      const groupedMembers = groupedPendingMembersMap.current.get(patientId);

      if (member.isPatient) {
        groupedMembers.patient = member;
      } else {
        groupedMembers.contacts.push(member);
      }
    }

    const newGroupedPendingMembers = [];
    const newPendingMemberIds = [];
    groupedPendingMembersMap.current.forEach((groupedMembers) => {
      const group = [];

      if (groupedMembers.patient) {
        newPendingMemberIds.push(groupedMembers.patient.id);
        group.push(groupedMembers.patient);
      }

      if (groupedMembers.contacts.length > 0) {
        groupedMembers.contacts.forEach((contact) => {
          newPendingMemberIds.push(contact.id);

          if (groupedMembers.patient) {
            group.push(contact);
          } else {
            newGroupedPendingMembers.push([contact]);
          }
        });
      }
      if (group.length > 0) {
        newGroupedPendingMembers.push(group);
      }
    });

    setPendingMemberIds(newPendingMemberIds);
    setGroupedPendingMembers(newGroupedPendingMembers);
  };

  const toggleSelectedMember = (token) => {
    if (selectedMemberIds.includes(token)) {
      setSelectedMemberIds(selectedMemberIds.filter((id) => id !== token));
    } else {
      setSelectedMemberIds([...selectedMemberIds, token]);
    }
  };

  const toggleSort = (newSortBy) => {
    setSortOrder(sortBy !== newSortBy ? 'asc' : sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(newSortBy);
  };

  useEffect(() => {
    refreshListMembers({
      query: currentMembersSearchQuery,
      sortBy: [sortBy, 'is_patient_contact', 'display_name'],
      sortOrder: [sortOrder, 'asc', 'asc'],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMembersSearchQuery, sortBy, sortOrder]);

  useEffect(() => {
    resetForm(_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  useEffect(() => {
    if (id && shouldFetchBroadcastList) {
      setShouldFetchBroadcastList(false);
      fetchBroadcastList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBroadcastList, shouldFetchBroadcastList]);

  useEffect(() => {
    results.forEach((member) => {
      if (!groupedMembersMap.current.has(member.patientId)) {
        groupedMembersMap.current.set(member.patientId, []);
      }

      const groupedMembers = groupedMembersMap.current.get(member.patientId);

      const isGroupedMembersEmpty = groupedMembers.length === 0;
      const isNewMemberInGroupedMembers = groupedMembers.find(({ id }) => member.id === id);

      if (isGroupedMembersEmpty || !isNewMemberInGroupedMembers) {
        groupedMembers.push(member);
      }
    });

    const sortedGroupedMembers = [...groupedMembersMap.current.values()].sort(
      ([{ displayName: a }], [{ displayName: b }]) =>
        sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)
    );

    setGroupedMembers(sortedGroupedMembers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return (
    <div className={classes()}>
      <div className={classes('breadcrumbs')}>
        <button className={classes('parent')} onClick={searchBroadcastLists}>
          PATIENT BROADCAST LISTS
        </button>
        &gt;{' '}
        <span className={classes('child')}>{broadcastListDetails.displayName || 'UNSAVED'}</span>
      </div>
      <div className={classes('form')}>
        <PatientBroadcastFormHeader
          broadcastListDetails={broadcastListDetails}
          isAdmin={isAdmin}
          isSaving={isSaving}
          listId={id}
          listError={listError}
          setListError={setListError}
          origin={BroadcastListOrigins.PATIENT}
          resetForm={resetForm}
          peopleCount={totalHits}
          setBroadcastListDetails={setBroadcastListDetails}
          setIsSaving={setIsSaving}
        />
        <div className={classes('buttons-and-search')}>
          <button
            className={classes('add-members-button')}
            data-test-id={'broadcast-add-members'}
            disabled={!id}
            onClick={() => setSearchTriggered(true)}
          >
            Add Members
          </button>
          {id && (
            <>
              {isAdmin && (
                <button
                  className={classes('delete-list-button')}
                  data-test-id={'broadcast-delete-list'}
                  onClick={() =>
                    openModal('deleteBroadcastList', {
                      id,
                      searchBroadcastLists,
                    })
                  }
                >
                  Delete List
                </button>
              )}
              <div className={classes('refresh-button-container')}>
                <RefreshButton refreshList={() => refreshListMembers({ reloadList: true })} />
              </div>
              <CollapsingSearchBar
                className={classes('search-bar')}
                handleQueryChange={handleCurrentMembersSearchQueryChange}
                query={currentMembersSearchQuery}
              />
            </>
          )}
          {selectedMemberIds.length > 0 && (
            <button
              data-test-id={'broadcast-remove'}
              onClick={handleBulkRemove}
              className={classes('actions-right-button')}
            >
              <DeleteButtonSvg className={classes('delete-icon')} aria-hidden />
              <span className={classes('delete-icon-text')}>Remove</span>
            </button>
          )}
        </div>
        {searchTriggered && (
          <OutsideClickHandler onOutsideClick={() => setSearchTriggered(false)}>
            <PatientSearchBox
              currentMemberIds={currentMemberIds}
              pendingMemberIds={pendingMemberIds}
              togglePendingMembers={togglePendingMembers}
              setSearchTriggered={setSearchTriggered}
              searchTriggered={searchTriggered}
            />
          </OutsideClickHandler>
        )}
        <PatientBroadcastListDetails
          groupedMembers={groupedMembers}
          groupedPendingMembers={groupedPendingMembers}
          hasQuery={currentMembersSearchQuery !== ''}
          isLoading={isLoading}
          queryValue={currentMembersSearchQuery}
          removePendingMember={togglePendingMembers}
          resetPendingMembers={resetPendingMembers}
          saveInProgress={addingPendingMembers}
          savePendingMembers={handleBulkAdd}
          scrollContainerRef={scrollContainerRef}
          selectedMemberIds={selectedMemberIds}
          sortBy={sortBy}
          sortOrder={sortOrder}
          toggleSelectedMember={toggleSelectedMember}
          toggleSort={toggleSort}
        />
        <div className={classes('loading', { isLoading })}>
          <DotsIndicator color={'#969696'} size={13} />
        </div>
      </div>
      {isCSVUploading && <ProcessingIndicator />}
    </div>
  );
};

PatientBroadcastForm.propTypes = {
  addBroadcastListMembers: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  findAllBroadcastListMemberIds: PropTypes.func.isRequired,
  id: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  loadBroadcastList: PropTypes.func.isRequired,
  loadBroadcastListMembers: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  searchBroadcastLists: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  patientAdminStore: [
    'addBroadcastListMembers',
    'findAllBroadcastListMemberIds',
    'loadBroadcastList',
    'loadBroadcastListMembers',
  ],
  modalStore: ['openModal'],
  patientStore: ['destroy', 'isCSVUploading'],
})(PatientBroadcastForm);
