import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import * as Sentry from '@sentry/react';

import { collabSlice } from './Collaboration/collab';
import { entitiesThunk, entitiesSlice } from './Collaboration/entities';
import { rolesThunk, rolesSlice } from './Collaboration/roles';
import { tagsThunk, tagsSlice } from './Collaboration/tags';
import { teamsThunk, teamsSlice } from './Collaboration/teams';
import { patientContextThunk, patientContextSlice } from './patientContext';
import { sessionSlice, sessionThunk } from './session';
import { sdkApi } from './api';
import { uiSlice } from './ui';
import TCClient from 'client';
import { adminSlice, adminThunks } from 'admin/store/admin';
import { settingsSlice, settingsThunks } from 'admin/store/admin/orgSettings';
import { locationSlice } from 'admin/store/admin/multiOrg/locations';

export type ReduxState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector;

const sentryEnhancer = Sentry.createReduxEnhancer({});

export const setupReduxStore = ({ sentry = true }: { sentry?: boolean } = {}) => {
  const store = configureStore({
    enhancers: sentry ? [sentryEnhancer] : undefined,
    reducer: {
      ui: uiSlice.reducer,
      session: sessionSlice.reducer,
      patientContext: patientContextSlice.reducer,
      collab: collabSlice.reducer,
      entities: entitiesSlice.reducer,
      roles: rolesSlice.reducer,
      tags: tagsSlice.reducer,
      teams: teamsSlice.reducer,
      admin: adminSlice.reducer,
      orgSettings: settingsSlice.reducer,
      multiOrg: locationSlice.reducer,
      [sdkApi.reducerPath]: sdkApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: TCClient,
        },
        serializableCheck: {
          // Ignore these field paths in all actions
          ignoredActionPaths: ['payload'],
          // Ignore these paths in the state
          ignoredPaths: ['collab', 'entities', 'admin', 'sdkApi', 'session.openModal.data.onClick'],
        },
      }).concat(sdkApi.middleware),
  });

  setupListeners(store.dispatch);
  return store;
};

export const store = setupReduxStore();

export const actions = {
  ...uiSlice.actions,
  ...sessionSlice.actions,
  ...patientContextSlice.actions,
  ...collabSlice.actions,
  ...entitiesSlice.actions,
  ...rolesSlice.actions,
  ...tagsSlice.actions,
  ...teamsSlice.actions,
  ...adminSlice.actions,
  ...settingsSlice.actions,
  ...locationSlice.actions,
};

export const thunk = {
  ...sessionThunk,
  ...patientContextThunk,
  ...entitiesThunk,
  ...rolesThunk,
  ...tagsThunk,
  ...teamsThunk,
  ...adminThunks,
  ...settingsThunks,
};
