import React, {
  ForwardedRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { last, flatten } from 'lodash';
import classNames from 'classnames';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { handlePaste } from '../../utils/handlePaste';
import MentionMemberFloatingModal from '../Mentions/MentionMemberFloatingModal';
import FormattingButtonsContentEditable from './FormattingButtonsContentEditable';
import BEM from 'common/bem';
import shouldRunCommandBot from 'common/utils/shouldRunCommandBot';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
import { UISliceState } from 'redux-stores/ui';
import { Entity, Conversation, Role, User } from 'types';

const { setMessageBodyInputFocus } = actions;
const INTENT_MENTIONS_REGEX_EXPRESSION = /(^|\s)@(\w*[\w.;{]*)$/;

const classes = BEM.with('MessageBodyInput');

type MentionsPayloadProps = {
  entity_type: string;
  entity_id: string;
  start: number;
  end: number;
};

type MessageBodyContentEditableInputProps = {
  addAttachments: (files: File[]) => void;
  body: string;
  borderColor?: string;
  className: string;
  currentConversation?: Conversation;
  currentUserId?: string;
  isCommandBotEnabled: boolean;
  isCommandEditorOpen: boolean;
  isGiphyBotEnabled: boolean;
  onBodyChange?: () => void;
  onClick: () => void;
  onHeightChange: () => void;
  onShiftTab: (event: KeyboardEvent) => void;
  onSubmit: (event: KeyboardEvent) => void;
  onTyping?: () => void;
  ref: (ref: Ref<HTMLElement>) => void;
  richTextFormat: boolean;
  setBody: (body: string) => void;
  setCommandInfo: () => void;
  tabIndex: number;
  toggleCommandEditor: () => void;
  setIsComposeFromLink: (isComposeFromLink: boolean) => void;
  allowTypedMentions?: boolean;
  isComposeFromLink?: boolean;
};

type InsertAtCursorHandle = {
  insertAtCursor: (text: string) => void;
  insertMentionAtCursor: (entities: Entity[]) => void;
};

const MessageBodyContentEditableInput = (
  {
    addAttachments,
    allowTypedMentions,
    body,
    borderColor,
    className,
    currentConversation,
    currentUserId,
    isCommandBotEnabled,
    isCommandEditorOpen,
    isComposeFromLink,
    isGiphyBotEnabled,
    onBodyChange,
    onClick,
    onHeightChange,
    onShiftTab,
    onSubmit,
    onTyping,
    setBody,
    setCommandInfo,
    richTextFormat,
    setIsComposeFromLink,
    tabIndex,
    toggleCommandEditor,
  }: MessageBodyContentEditableInputProps,
  ref: ForwardedRef<InsertAtCursorHandle>
) => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLDivElement>(null);
  const [rangeMemory, setRangeMemory] = useState<Range>();
  const [mentionMemberFloatingModal, setMentionMemberFloatingModal] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<{ node?: ChildNode | DocumentFragment }>();
  const [firstEnter, setFirstEnter] = useState<boolean>(true);
  const { accessibilityMode, messageBodyInputFocus } = useAppSelector(
    ({ ui }: { ui: UISliceState }) => ({
      accessibilityMode: ui.accessibilityMode,
      messageBodyInputFocus: ui.messageBodyInputFocus,
    })
  );

  const flexDirection = allowTypedMentions ? 'column' : 'row';

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const { altKey, key, shiftKey } = event;

      if (key === 'Enter' && !(shiftKey || altKey)) {
        // Return
        onSubmit(event);
      } else if (key === 'Shift' && shiftKey) {
        // TAB
        onShiftTab && onShiftTab(event);
      }
    },
    [onShiftTab, onSubmit]
  );

  useEffect(() => {
    if (accessibilityMode) return;
    if (messageBodyInputFocus && document.activeElement !== inputRef.current) {
      inputRef.current?.focus();
    } else if (!messageBodyInputFocus && document.activeElement === inputRef.current) {
      inputRef.current?.blur();
    }
  }, [accessibilityMode, messageBodyInputFocus, dispatch]);

  useEffect(() => {
    function _focus() {
      dispatch(setMessageBodyInputFocus(true));
    }
    function _blur() {
      const selObj = window.getSelection();
      const selRange = selObj && selObj.rangeCount > 0 ? selObj.getRangeAt(0) : null;
      if (selRange) {
        setRangeMemory(selRange);
      }

      dispatch(setMessageBodyInputFocus(false));
    }
    const resizeObserver = new ResizeObserver(() => {
      onHeightChange?.();
    });
    const theRef = inputRef.current;
    theRef?.addEventListener('focus', _focus);
    theRef?.addEventListener('blur', _blur);
    theRef?.addEventListener('keypress', handleKeyPress);
    theRef && resizeObserver.observe(theRef);
    return () => {
      theRef?.removeEventListener('focus', _focus);
      theRef?.removeEventListener('blur', _blur);
      theRef?.removeEventListener('keypress', handleKeyPress);
      theRef && resizeObserver.unobserve(theRef);
    };
  }, [inputRef, dispatch, handleKeyPress, onHeightChange]);

  useEffect(() => {
    if (firstEnter && isComposeFromLink) {
      !accessibilityMode && dispatch(setMessageBodyInputFocus(true));
      setIsComposeFromLink(false);
    } else if (!firstEnter && !isComposeFromLink) {
      setFirstEnter(false);
    }
  }, [accessibilityMode, dispatch, firstEnter, isComposeFromLink, setIsComposeFromLink]);

  const insertMentionMemberSelector = () => {
    setMentionMemberFloatingModal(!mentionMemberFloatingModal);
    const div = document.createElement('div');
    div.setAttribute('class', classes('@'));
    div.innerText = '@';
    insertElementsAtCursor([div]);
  };

  const insertElementsAtCursor = (
    elms: (Element | ChildNode | DocumentFragment)[],
    cursorNode?: Element | ChildNode
  ) => {
    const selObj = window.getSelection();
    let selRange = selObj && selObj.rangeCount > 0 ? selObj.getRangeAt(0) : null;
    if (selRange?.commonAncestorContainer !== inputRef.current && rangeMemory)
      selRange = rangeMemory;
    let lastNode = last(elms);
    if (lastNode?.nodeType === 11 && lastNode.lastChild) lastNode = lastNode.lastChild;

    selRange?.deleteContents();
    elms.reverse().forEach((elm) => selRange?.insertNode(elm));
    dispatch(setMessageBodyInputFocus(true));
    setCursorPosition({ node: cursorNode || lastNode });
    setBody(inputRef?.current?.innerHTML || '');
  };

  const insertAtCursor = (text: string) => {
    const nodes = text
      .split('\n')
      .map((t) => [document.createTextNode(t), document.createElement('br')]);
    nodes[nodes.length - 1].pop();
    const fragments = new DocumentFragment();
    fragments.append(...flatten(nodes));

    insertElementsAtCursor([fragments]);
  };

  const insertMentionElementsAtCursor = (
    elms: (Element | ChildNode | DocumentFragment)[],
    cursorNode?: Element | ChildNode | null
  ) => {
    const selObj = window.getSelection();
    let selRange = selObj && selObj.rangeCount > 0 ? selObj.getRangeAt(0) : null;

    if (selRange?.commonAncestorContainer !== inputRef.current && rangeMemory)
      selRange = rangeMemory;
    let lastNode = last(elms);
    if (lastNode?.nodeType === 11 && lastNode.lastChild) lastNode = lastNode.lastChild;

    selRange?.deleteContents();
    elms.reverse().forEach((elm) => selRange?.insertNode(elm));
    dispatch(setMessageBodyInputFocus(true));
    setCursorPosition({ node: cursorNode || lastNode });
    setBody(inputRef?.current?.innerHTML || '');
  };

  const insertMentionAtCursor = (entities: Entity[]) => {
    let lastInsertedNode: Node | null = null;
    const elements: (Element | ChildNode | DocumentFragment)[] = [];

    entities.forEach((entity) => {
      const parentSpan = document.createElement('span');
      const outSpan = document.createElement('span');
      const inSpan = document.createElement('span');
      const topSpan = document.createElement('span');
      const bottomSpan = document.createElement('span');

      outSpan.setAttribute('mentionEntityType', entity.$entityType);
      parentSpan.setAttribute('contenteditable', 'false');
      outSpan.setAttribute('mentionId', entity?.botUser?.id || entity.id);
      inSpan.setAttribute('class', classes('mention'));
      inSpan.innerText = `@${entity.displayName}`;

      outSpan.onclick = (e: MouseEvent) => {
        const childNodes = Array.from((e.target as Node)?.parentNode?.parentNode?.childNodes || []);
        const targetIndex = childNodes.findIndex((n) => n.firstChild?.nextSibling === e.target);
        const selectedNode = childNodes[targetIndex + 1];
        setCursorPosition({ node: selectedNode });
      };

      parentSpan.append(topSpan);
      outSpan.append(inSpan);
      parentSpan.append(outSpan);
      parentSpan.append(bottomSpan);

      elements.push(parentSpan);
      const space = document.createTextNode('\xA0');
      elements.push(space);

      lastInsertedNode = space;
    });

    insertMentionElementsAtCursor(elements, lastInsertedNode);
  };

  const insertOneMentionAtCursor = (entity: User | Role) => {
    const parentSpan = document.createElement('span');
    const outSpan = document.createElement('span');
    const inSpan = document.createElement('span');
    const topSpan = document.createElement('span');
    const bottomSpan = document.createElement('span');
    outSpan.setAttribute('mentionEntityType', entity.$entityType);
    parentSpan.setAttribute('contenteditable', 'false');
    outSpan.setAttribute('mentionId', (entity as Role).botUserId || entity.id);
    inSpan.setAttribute('class', classes('mention'));
    inSpan.innerText = `${entity.displayName}`;
    outSpan.onclick = (e: MouseEvent) => {
      const childNodes = Array.from((e.target as Node)?.parentNode?.parentNode?.childNodes || []);
      const targetIndex = childNodes.findIndex((n) => n.firstChild?.nextSibling === e.target);
      const selectedNode = childNodes[targetIndex + 1];
      setCursorPosition({ node: selectedNode });
    };
    parentSpan.append(topSpan);
    outSpan.append(inSpan);
    parentSpan.append(outSpan);
    parentSpan.append(bottomSpan);
    insertElementsAtCursor([parentSpan, document.createTextNode('\xA0')]);
  };

  const handlePasteCallback = useRef((event: ClipboardEvent) =>
    handlePaste(addAttachments, event, window, insertAtCursor)
  );

  useEffect(() => {
    const theRef = inputRef.current;
    const { current: callback } = handlePasteCallback;
    theRef?.addEventListener('paste', callback);
    return () => {
      theRef?.removeEventListener('paste', callback);
    };
  }, [inputRef, addAttachments]);

  useEffect(() => {
    if (inputRef.current && cursorPosition !== undefined) {
      const range = document.createRange();
      const sel = window.getSelection();
      if (cursorPosition.node) {
        range.selectNodeContents(cursorPosition.node || inputRef.current);
        range.collapse(false);
      } else {
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
      }
      sel?.removeAllRanges();
      sel?.addRange(range);
      inputRef.current.focus();
      setCursorPosition(undefined);
    }
  }, [inputRef, cursorPosition]);

  const onChange = useCallback(
    (event: ContentEditableEvent) => {
      const body = event.target.value;

      setBody(body);
      if (body) {
        onBodyChange?.();
        onTyping?.();

        if (allowTypedMentions) {
          const selection = window.getSelection();
          if (!selection || !selection?.rangeCount) return;

          const range = selection.getRangeAt(0);
          const startContainer = range.startContainer;
          const startOffset = range.startOffset;

          let curPosition = 0;

          const traverseNodes = (node: Node) => {
            if (node === startContainer) {
              curPosition += startOffset;
              return true;
            }

            if (node.nodeType === Node.TEXT_NODE) {
              curPosition += node.textContent?.length || 0;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
              for (let i = 0; i < node.childNodes.length; i++) {
                if (traverseNodes(node.childNodes[i])) {
                  return true;
                }
              }
            }

            return false;
          };

          traverseNodes(range.commonAncestorContainer);

          const slicedText = range.commonAncestorContainer.textContent?.slice(0, curPosition) ?? '';

          const match = INTENT_MENTIONS_REGEX_EXPRESSION.exec(slicedText);
          setMentionMemberFloatingModal(Boolean(match));
        }

        if (isCommandBotEnabled || isGiphyBotEnabled) {
          shouldRunCommandBot({
            bodyText: body,
            isGiphyBotEnabled,
            isCommandBotEnabled,
            isCommandEditorOpen,
            setState: setCommandInfo,
            toggleCommandEditor,
          });
        }
      } else {
        if (isCommandEditorOpen) toggleCommandEditor();

        if (mentionMemberFloatingModal) {
          setMentionMemberFloatingModal(false);
        }
      }
    },
    [
      setBody,
      onBodyChange,
      onTyping,
      allowTypedMentions,
      isCommandBotEnabled,
      isGiphyBotEnabled,
      isCommandEditorOpen,
      setCommandInfo,
      toggleCommandEditor,
      mentionMemberFloatingModal,
    ]
  );

  const getMentionMembersData = () => {
    const input = inputRef.current?.childNodes;
    const inputText = inputRef.current?.innerText;
    let individualMentionsCount = 0,
      roleMentionsCount = 0,
      startIndex = 0;

    const mentionMembersPayload = Object.values(input || []).reduce((acc, child: ChildNode) => {
      if (child?.firstChild && child.firstChild.nodeName === 'SPAN') {
        const getFirstChildElement = child.firstChild.nextSibling?.firstChild as HTMLElement;
        const getParentChildElement = child.firstChild.nextSibling as HTMLElement;
        const userDisplayName = getFirstChildElement.innerText;
        const mentionEntityType = getParentChildElement.getAttribute('mentionentitytype');
        const mentionId = getParentChildElement.getAttribute('mentionid');
        startIndex = inputText?.indexOf(userDisplayName, startIndex) || 0;
        const endIndex = startIndex + userDisplayName.length;
        if (mentionEntityType === 'role') {
          roleMentionsCount++;
        } else {
          individualMentionsCount++;
        }
        acc.push({
          entity_type: mentionEntityType || '',
          entity_id: mentionId || '',
          start: startIndex,
          end: endIndex,
        });
        startIndex += userDisplayName.length - 1;
      } else {
        const getChildText = child as Text;
        startIndex += getChildText.length || 0;
      }

      return acc;
    }, [] as MentionsPayloadProps[]);

    return {
      individualMentionsCount,
      mentionMembersPayload,
      messageEditableBody: inputText,
      roleMentionsCount,
    };
  };

  const trimMessageBody = useCallback(() => {
    let currentBodyTrim = body.trim();

    currentBodyTrim = currentBodyTrim.replace(/\n/g, '<br>');

    while (currentBodyTrim !== currentBodyTrim.replace(/ +|\s+|&nbsp;/g, ' ')) {
      currentBodyTrim = currentBodyTrim.replace(/ +|\s+|&nbsp;/g, ' ');
    }

    while (currentBodyTrim !== currentBodyTrim.replace(/^ +|^<br>+/g, '')) {
      currentBodyTrim = currentBodyTrim.replace(/^ +|^<br>+/g, '');
    }

    return currentBodyTrim;
  }, [body]);

  useImperativeHandle(ref, () => ({
    insertAtCursor,
    insertMentionAtCursor,
    insertMentionMemberSelector,
    getMentionMembersData,
    trimMessageBody,
  }));

  return (
    <div className={classNames(classes(), className)} style={{ flexDirection }}>
      {richTextFormat && (
        <FormattingButtonsContentEditable insertElementsAtCursor={insertElementsAtCursor} />
      )}
      {mentionMemberFloatingModal && currentConversation && currentUserId && (
        <MentionMemberFloatingModal
          currentUserId={currentUserId}
          currentConversation={currentConversation}
          selectEntity={insertOneMentionAtCursor}
          setMentionMemberFloatingModal={setMentionMemberFloatingModal}
        />
      )}
      {useMemo(
        () => (
          <ContentEditable
            className={classes('input')}
            aria-label={'Message Input Box'}
            html={body}
            innerRef={inputRef}
            onChange={onChange}
            onClick={onClick}
            placeholder="Type message here"
            style={{ borderColor }}
            tabIndex={tabIndex}
          />
        ),
        [body, borderColor, inputRef, onChange, onClick, tabIndex]
      )}
    </div>
  );
};

export default forwardRef<InsertAtCursorHandle, MessageBodyContentEditableInputProps>(
  MessageBodyContentEditableInput
);
