import { useEffect, useState } from 'react';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';
import { copyToClipboard, mobxInjectSelect } from '../../../../../common/utils';
import { Table, TableRow } from '../../../../shared-components/Table/Table';
import { TabProps } from '..';
import TCClient from '../../../../../client';
import AttributeRow from './AttributeRow';
import styles from './SCIM.module.css';
import { CtaButton } from 'admin/shared-components/CtaButton/CtaButton';
import { Input } from 'admin/shared-components/Input/Input';
import { Toast } from 'admin/shared-components/Toast/Toast';
import { Banner } from 'common/components';
import Modal from 'admin/shared-components/Modal/Modal';
import { Attribute, SetupScimParam } from 'types';
import { useAppSelector } from 'redux-stores';

type MobxProps = {
  scimStatus: string;
  scimBaseUrl: string;
  hasAdmin: boolean;
  loadBearerToken: (orgId: string) => Promise<string>;
  setupScim: (params: SetupScimParam) => Promise<string>;
  loadConfiguration: (orgId: string) => Promise<Attribute[]>;
  attributes: Attribute[];
};

function SCIM({
  onClose,
  loadConfiguration,
  loadBearerToken,
  hasAdmin,
  setupScim,
  scimBaseUrl,
  scimStatus,
}: TabProps & MobxProps) {
  const { currentOrganizationId, currentOrganizationName } = useAppSelector((state) => ({
    currentOrganizationId: state.orgSettings.settings.id,
    currentOrganizationName: state.orgSettings.settings.name,
  }));
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  const [prefixField, setPrefixField] = useState('');
  const [suffixField, setSuffixField] = useState('');
  const [bearerToken, setBearerToken] = useState('');
  const [isFailureToastOpen, setIsFailureToastOpen] = useState(false);
  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [rows, setRows] = useState<Array<TableRow>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const hasScimBaseUrl = scimBaseUrl.length > 0;
  const readableStatusLabel = `${scimStatus.charAt(0).toLocaleUpperCase() + scimStatus.slice(1)}`;
  const columns = [
    {
      field: 'name',
      headerName: 'Attribute',
      customCellStyles: { width: '120px', verticalAlign: 'top' },
    },
    { field: 'description', headerName: 'Description' },
  ];

  useEffect(() => {
    async function getSCIMSettings() {
      try {
        await loadConfiguration(currentOrganizationId);
        const availableScimFields = await TCClient.scim.findAvailableSCIMFields(
          currentOrganizationId
        );
        const attributes = (availableScimFields.attributes as Attribute[]) ?? [];
        const rows = attributes.map((attribute) => ({
          id: attribute.name,
          name: attribute.name,
          description: <AttributeRow attribute={attribute} />,
        }));
        setRows(rows);
      } finally {
        setIsLoading(false);
      }
    }
    getSCIMSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenerateBtn = async () => {
    const token = hasAdmin
      ? await loadBearerToken(currentOrganizationId)
      : await setupScim({
          organizationId: currentOrganizationId,
          clientName: currentOrganizationName,
          suffix: suffixField,
          prefix: prefixField,
        });
    if (token) {
      setBearerToken(token);
      setIsTokenModalOpen(true);
    } else {
      setToastMessage(
        'Error encountered while generating token. Contact support if the issue persists'
      );
      setIsFailureToastOpen(true);
    }
  };

  async function handleCopyToken() {
    copyToClipboard(bearerToken);
    setToastMessage('Bearer token has been copied to your clipboard.');
    setIsSuccessToastOpen(true);
  }

  return (
    <ViewContainer
      {...{
        header: 'SCIM Provisioning',
        onClose,
      }}
    >
      <div className={styles.scimProvisioning}>
        <div className={styles.section}>
          <div className={styles.settingRow}>
            <div className={styles.label} data-test-id="base-url-label">
              Base Url:
            </div>
            <div className={styles.rowElements}>
              <Input
                dataTestId="base-url-text-input"
                disabled
                value={scimBaseUrl}
                customCSS={styles.input}
              />
              <CtaButton
                dataTestId="copy-base-url-button"
                primary
                disabled={!hasScimBaseUrl}
                label="Copy"
                onClick={async () => {
                  if (hasScimBaseUrl) {
                    copyToClipboard(scimBaseUrl);
                    setToastMessage('Url has been copied to your clipboard.');
                    setIsSuccessToastOpen(true);
                  } else {
                    setToastMessage('Url is not available');
                    setIsFailureToastOpen(true);
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.settingRow}>
            <div className={styles.label} data-test-id="bearer-token-label">
              BearerToken:
            </div>
            <div className={styles.rowElements}>
              <CtaButton
                primary
                dataTestId="generate-bearer-token-button"
                label="Generate"
                onClick={handleGenerateBtn}
              />
            </div>
          </div>
          <div className={styles.settingRow}>
            <div className={styles.label} data-test-id="prefix-label">
              Prefix:
            </div>
            <Input
              dataTestId="prefix-text-input"
              customCSS={styles.input}
              placeholder={'Add prefix'}
              onInputChange={(e) => {
                setPrefixField(e.currentTarget.value);
              }}
              value={prefixField}
            />
          </div>
          <div className={styles.settingRow}>
            <div className={styles.label} data-test-id="suffix-label">
              Suffix:
            </div>
            <Input
              customCSS={styles.input}
              dataTestId="suffix-text-input"
              placeholder={'Add suffix'}
              onInputChange={(e) => {
                setSuffixField(e.currentTarget.value);
              }}
              value={suffixField}
            />
          </div>
          <div className={styles.settingRow}>
            <div className={styles.label} data-test-id="status-label">
              Status:
            </div>
            <Banner type={readableStatusLabel} dataTestId="status-banner" />
          </div>
        </div>
        <Modal
          body={`For your security, we will not store a record of this bearer token and it will disappear once you close this page. If you have any issues with setting up the integration, you can always come back and generate a new bearer token.`}
          isOpen={isTokenModalOpen}
          closeButton={'Close'}
          canClose
          doneButton={'Copy Token'}
          header={'Bearer Token'}
          modalSize={'large'}
          isWarningButtonPresent={true}
          onClose={() => {
            setIsTokenModalOpen(false);
          }}
          onSuccess={handleCopyToken}
        />
        <Toast
          message={toastMessage}
          type={'FAILURE'}
          open={isFailureToastOpen}
          onClose={() => {
            setIsFailureToastOpen(false);
          }}
          autoHideDuration={2000}
        />
        <Toast
          type="SUCCESS"
          message={toastMessage}
          open={isSuccessToastOpen}
          onClose={() => {
            setIsSuccessToastOpen(false);
          }}
          autoHideDuration={2000}
        />
        <div className={styles.section}>
          <div className={styles.header}>Available Attributes</div>
          <div>
            <Table rows={rows} columns={columns} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </ViewContainer>
  );
}

export default mobxInjectSelect<TabProps, MobxProps>({
  scimStore: [
    'scimStatus',
    'scimBaseUrl',
    'loadConfiguration',
    'loadBearerToken',
    'setupScim',
    'hasAdmin',
  ],
})(SCIM);
