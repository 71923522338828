import { useState } from 'react';
import { ViewContainer } from '../../../../shared-components/View/ViewContainer/ViewContainer';

import { TabProps } from './../';
import PersonaTable from './PersonaTable';

export default function Persona({ onClose }: TabProps) {
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState(false);
  return (
    <ViewContainer
      {...{
        header: 'Persona',
        subHeader: '',
        onClose,
      }}
    >
      <div>
        <PersonaTable
          isCreateMode={isCreateMode}
          setIsCreateMode={setIsCreateMode}
          isSuccessToastOpen={isSuccessToastOpen}
          setIsSuccessToastOpen={setIsSuccessToastOpen}
        />
      </div>
    </ViewContainer>
  );
}
